.home-hero{
  align-self: end;
  position: absolute;
  justify-self: flex-end;
  right: 0;
}

@media (max-width: 576px) {
  .home-sm-hero {
    max-width: 170px; /* Puedes ajustar el maxWidth según sea necesario */
  }
}
@media (min-width: 577px) and (max-width: 991px) {
  .home-md-hero {
    max-width: 198px;

  }
}

@media (min-width: 1197px) {
  .home-lg-hero {
    max-width: 400px;
  }
}