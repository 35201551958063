hr {
  border: 0; 
  height: 4px; 
  background: linear-gradient(to right, #ca820f 0%, #fbb810 25%, rgb(2 89 44) 25%, rgb(4 120 54) 50%, #a8021d 50%, #f60427 75%, #06b4b6 75%, #ADD8E6 100%);
  opacity: 1 !important;
  border-top : 0px !important;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
 }
 .responsive-jh{
  width: 100%; 
  height: auto; 
  display: block; 
  margin: 0 auto; 
}
.navbar-nav .nav-link.active, .navbar-nav .show > .nav-link {
  color: rgb(186 0 0) !important;
}
a.nav-link:hover {
  color: #fcb711 !important;
}
.ir-arriba {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  display: none;
  padding: 10px 20px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.ir-arriba:hover {
  background-color: #9e5e16 !important;;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media not all and (-webkit-min-device-pixel-ratio: 0) {
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: #cac3cb;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}

.fondo-rayas {
 background: linear-gradient(to right, #ca820f 0%, #fbb810 20%, rgb(2 89 44) 20%, rgb(4 120 54) 40%, #a8021d 40%, #f60427 60%, #06b4b6 60%, #06b4b6 80%, #421d0f 80%, #421d0f 100%);
  height: 100vh;
  width: 100vw;
}