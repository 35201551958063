.bi path:nth-of-type(4) {
    fill: #32aaad;
}
.bi path:nth-of-type(3) {
    fill: #a9021c;
}
.bi path:nth-of-type(2) {
    fill: #fbb612;
}
.bi path:nth-of-type(1) {
    fill: #0a7811;
}

circle {
    color: #fbb612;
}

.bi-wave path:nth-of-type(1) {
    fill: #32aaad !important;
}