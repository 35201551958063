@media (max-width: 576px) {
  .h-sm-logo {
    max-width: 280px;
  }
}
@media (min-width: 577px) and (max-width: 768px) {
  .h-md-logo {
    max-width: 300px;
  }
}
@media (min-width: 769px) {
  .h-lg-logo {
    max-width: 550px;
  }
}

@media (min-width: 100px) and (max-width: 768px) {
  .home-sm-text {
    background: linear-gradient(253deg, #d0d0d07a, #d9d9d9);
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .home-md-text {
    background: linear-gradient(227deg, #cecdcd7a, rgb(220 222 219));
  }
}